import React from 'react'

// utils
import classnames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { objectsToString } from '../../../../utils/objectsToString'

// types
import { className, children, propTypesClassName, propTypesChildren } from './type'
import list from './listStyle'

export interface ListItemSuffixProps extends React.ComponentProps<'div'> {
  className?: className
  children: children
}

export const ListItemSuffix = React.forwardRef<HTMLDivElement, ListItemSuffixProps>(
  ({ className, children, ...rest }, ref) => {
    // 1. init
    const { styles } = list

    // 2. set styles
    const listItemSuffixClasses = twMerge(
      classnames(objectsToString(styles?.base?.itemSuffix)),
      className,
    )

    return (
      <div {...rest} ref={ref} className={listItemSuffixClasses}>
        {children}
      </div>
    )
  },
)

ListItemSuffix.propTypes = {
  className: propTypesClassName,
  children: propTypesChildren,
}

ListItemSuffix.displayName = 'ListItemSuffix'

export default ListItemSuffix
