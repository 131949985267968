// types

import { className } from './type'

export interface ListStylesType {
  defaultProps?: {
    className?: className
  }
  styles?: {
    base?: {
      list?: object
      item?: {
        initial?: object
        selected?: object
        disabled?: object
      }
      itemPrefix?: object
      itemSuffix?: object
    }
  }
}

export const list: ListStylesType = {
  defaultProps: {
    className: '',
  },
  styles: {
    base: {
      list: {
        display: 'flex',
        flexDirection: 'flex-col',
        minWidth: 'min-w-[240px]',
        p: 'p-2',
        fontSize: 'text-base',
        fontWeight: 'font-normal',
      },
      item: {
        initial: {
          display: 'flex',
          alignItems: 'items-center',
          width: 'w-full',
          padding: 'p-3',
          borderRadius: 'rounded-sm',
          textAlign: 'text-start',
          lightHeight: 'leading-tight',
          transition: 'transition-all',
          bg: 'hover:bg-slate-50 hover:bg-opacity-80 focus:bg-slate-50 focus:bg-opacity-80 active:bg-slate-50 active:bg-opacity-80',
          color: 'hover:text-slate-900 focus:text-slate-900 active:text-slate-900',
          outline: 'outline-none',
        },
        selected: {
          bg: 'bg-slate-50',
        },
        disabled: {
          opacity: 'opacity-50',
          cursor: 'cursor-not-allowed',
          pointerEvents: 'pointer-events-none',
          userSelect: 'select-none',
          bg: 'hover:bg-transparent focus:bg-transparent active:bg-transparent',
          color: 'hover:text-slate-500 focus:text-slate-500 active:text-slate-500',
        },
      },
      itemPrefix: {
        display: 'grid',
        placeItems: 'place-items-center',
        marginRight: 'mr-4',
      },
      itemSuffix: {
        display: 'grid',
        placeItems: 'place-items-center',
        marginRight: 'ml-auto justify-self-end',
      },
    },
  },
}

export default list
