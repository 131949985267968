import React from 'react'

// utils
import classnames from 'classnames'
import { twMerge } from 'tailwind-merge'

// https://www.material-tailwind.com/docs/react/list
// list components
import { ListItem, ListItemProps } from './list/ListItem'
import { ListItemPrefix, ListItemPrefixProps } from './list/ListItemPrefix'
import { ListItemSuffix, ListItemSuffixProps } from './list/ListItemSuffix'
// types
import { children, className, propTypesChildren, propTypesClassName } from './list/type'
import list from './list/listStyle'
import { objectsToString } from '~/utils/objectsToString'

export interface ListProps extends React.ComponentProps<'div'> {
  className?: className
  children: children
}

export const List = React.forwardRef<HTMLDivElement, ListProps>(
  ({ className, children, ...rest }, ref) => {
    // 1. init
    const { defaultProps, styles } = list

    // 2. set default props
    className = twMerge(defaultProps?.className || '', className)

    // 3. set styles
    const listClasses = twMerge(
      classnames(objectsToString(styles?.base?.list)),
      className,
    )

    return (
      <nav {...rest} ref={ref} className={listClasses}>
        {children}
      </nav>
    )
  },
)

List.propTypes = {
  className: propTypesClassName,
  children: propTypesChildren,
}

List.displayName = 'List'

export type { ListItemProps, ListItemPrefixProps, ListItemSuffixProps }
export { ListItem, ListItemPrefix, ListItemSuffix }
export default Object.assign(List, {
  Item: ListItem,
  ItemPrefix: ListItemPrefix,
  ItemSuffix: ListItemSuffix,
})
