import React from 'react'

// utils
import classnames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { objectsToString } from '../../../../utils/objectsToString'

// types
import { className, children, propTypesClassName, propTypesChildren } from './type'
import list from './listStyle'

export interface ListItemPrefixProps extends React.ComponentProps<'div'> {
  className?: className
  children: children
}

export const ListItemPrefix = React.forwardRef<HTMLDivElement, ListItemPrefixProps>(
  ({ className, children, ...rest }, ref) => {
    // 1. init
    const { styles } = list

    // 2. set styles
    const listItemPrefixClasses = twMerge(
      classnames(objectsToString(styles?.base?.itemPrefix)),
      className,
    )

    return (
      <div {...rest} ref={ref} className={listItemPrefixClasses}>
        {children}
      </div>
    )
  },
)

ListItemPrefix.propTypes = {
  className: propTypesClassName,
  children: propTypesChildren,
}

ListItemPrefix.displayName = 'ListItemPrefix'

export default ListItemPrefix
