import React from 'react'

// utils
import classnames from 'classnames'
import { twMerge } from 'tailwind-merge'

// types
import {
  className,
  disabled,
  children,
  selected,
  propTypesClassName,
  propTypesDisabled,
  propTypesSelected,
  propTypesChildren,
} from './type'

// list item components
import { ListItemPrefix, ListItemPrefixProps } from './ListItemPrefix'
import { ListItemSuffix, ListItemSuffixProps } from './ListItemSuffix'
import list from './listStyle'
import { objectsToString } from '~/utils/objectsToString'

export interface ListItemProps extends React.ComponentProps<'div'> {
  className?: className
  disabled?: disabled
  selected?: selected
  children: children
}

export const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(
  ({ className, disabled, selected, children, ...rest }, ref) => {
    // 1. init
    const { styles } = list

    // 2. set styles
    const listItemClasses = twMerge(
      classnames(objectsToString(styles?.base?.item?.initial), {
        [objectsToString(styles?.base?.item?.disabled)]: disabled,
        [objectsToString(styles?.base?.item?.selected)]: selected && !disabled,
      }),
      className,
    )

    return (
      <div
        {...rest}
        ref={ref}
        role="button"
        tabIndex={0}
        className={listItemClasses}
        onMouseDown={(e) => {
          const onMouseDown = rest?.onMouseDown
          return typeof onMouseDown === 'function' && onMouseDown(e)
        }}>
        {children}
      </div>
    )
  },
)

ListItem.propTypes = {
  className: propTypesClassName,
  selected: propTypesSelected,
  disabled: propTypesDisabled,
  children: propTypesChildren,
}

ListItem.displayName = 'ListItem'

export type { ListItemPrefixProps, ListItemSuffixProps }
export { ListItemPrefix, ListItemSuffix }
export default Object.assign(ListItem, { Prefix: ListItemPrefix, Suffix: ListItemSuffix })
